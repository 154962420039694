import React, { useState, useEffect, useContext } from 'react';
import { graphql, navigate } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import {
  ModerationFilters,
  LoadingSpinner,
  LoadingSpinnerFullScreenWrapper,
  ProtectedRoute,
  SEO
} from 'components';
import { breakpoints } from 'styles';

const SiteWideModeration = ({ data }) => {
  const { loading, firebase, user } = useContext(FirebaseContext);
  const [isPendingFilterSelected, setIsPendingFilterSelected] = useState(true);
  const [pendingSiteRegistrants, setPendingSiteRegistrants] = useState(null);
  const [approvedSiteRegistrants, setApprovedSiteRegistrants] = useState(null);
  const [unsubscribeFromApprovedSiteRegistrants, setUnsubscribeFromApprovedSiteRegistrants] =
    useState();
  const [registrantAccessStates, setRegistrantAccessStates] = useState({});
  const [eventsUserCanAccess, setEventsUserCanAccess] = useState({});
  const events = data.allMarkdownRemark.edges.map(({ node }) => ({
    name: node.frontmatter.name,
    eid: node.frontmatter.eid
  }));

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/');
    }
  }, [loading, firebase, user]);

  useEffect(() => {
    let unsubscribeFromPendingSiteRegistrants;
    if (firebase) {
      unsubscribeFromPendingSiteRegistrants = firebase.subscribeToPendingSiteRegistrants({
        onSnapshot: (snapshot) => {
          if (!snapshot.metadata.fromCache) {
            if (!snapshot.empty) {
              const _pendingSiteRegistrants = snapshot.docs.map((doc) => doc.data());
              setPendingSiteRegistrants(_pendingSiteRegistrants);
            } else {
              setPendingSiteRegistrants([]);
            }
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPendingSiteRegistrants) {
        unsubscribeFromPendingSiteRegistrants();
      }
    };
  }, [firebase]);

  useEffect(() => {
    if (firebase && !isPendingFilterSelected && !unsubscribeFromApprovedSiteRegistrants) {
      setUnsubscribeFromApprovedSiteRegistrants((subscribed) => {
        if (!subscribed) {
          return firebase.subscribeToApprovedSiteRegistrants({
            onSnapshot: (snapshot) => {
              if (!snapshot.metadata.fromCache) {
                if (!snapshot.empty) {
                  const _approvedSiteRegistrants = snapshot.docs.map((doc) => doc.data());
                  setApprovedSiteRegistrants(_approvedSiteRegistrants);
                } else {
                  setApprovedSiteRegistrants([]);
                }
              }
            }
          });
        }
      });
    }
  }, [firebase, isPendingFilterSelected, unsubscribeFromApprovedSiteRegistrants]);

  useEffect(() => {
    if (unsubscribeFromApprovedSiteRegistrants) {
      return () => {
        if (unsubscribeFromApprovedSiteRegistrants) {
          unsubscribeFromApprovedSiteRegistrants();
        }
      };
    }
  }, [unsubscribeFromApprovedSiteRegistrants]);

  const handleCheckboxToggle = (e) => {
    e.persist();
    const { eid, uid } = e.target.dataset;
    if (e.target.checked) {
      setRegistrantAccessStates((currentState) => ({
        ...currentState,
        [uid]: {
          approving: false,
          denying: false,
          remindAdminToSelectAnEvent: false
        }
      }));
      setEventsUserCanAccess((users) => ({
        ...users,
        [uid]: users[uid]?.length ? [...users[uid], eid] : [eid]
      }));
    } else if (!e.target.checked) {
      setEventsUserCanAccess((users) => ({
        ...users,
        [uid]: users[uid].filter((_eid) => _eid !== eid)
      }));
    }
  };

  const resetFetchingState = (registrant) => {
    setRegistrantAccessStates((currentState) => ({
      ...currentState,
      [registrant.uid]: {
        approving: false,
        denying: false,
        remindAdminToSelectAnEvent: false
      }
    }));
  };

  const handleAccept = async (registrant) => {
    if (eventsUserCanAccess[registrant.uid]?.length) {
      try {
        setRegistrantAccessStates((currentState) => ({
          ...currentState,
          [registrant.uid]: {
            approving: true,
            denying: false,
            remindAdminToSelectAnEvent: false
          }
        }));
        setTimeout(async () => {
          await Promise.all([
            firebase.grantRegistrantAccessToTheseEvents({
              uid: registrant.uid,
              events: eventsUserCanAccess[registrant.uid]
            }),
            firebase.sendSiteAccessGrantedEmail({
              name: registrant.fullName,
              mail: registrant.email
            })
          ]);
          // await firebase.grantRegistrantAccessToTheseEvents({
          //   uid: registrant.uid,
          //   events: eventsUserCanAccess[registrant.uid]
          // });
          resetFetchingState(registrant);
          /* Duration of Framer Motion animation */
        }, 950);
      } catch (error) {
        console.error(error);
        resetFetchingState(registrant);
      }
    } else {
      setRegistrantAccessStates((currentState) => ({
        ...currentState,
        [registrant.uid]: {
          approving: false,
          denying: false,
          remindAdminToSelectAnEvent: true
        }
      }));
      setTimeout(() => {
        setRegistrantAccessStates((currentState) => ({
          ...currentState,
          [registrant.uid]: {
            approving: false,
            denying: false,
            remindAdminToSelectAnEvent: false
          }
        }));
      }, 1200); // 1200 is the length of the 300ms flash animation repeated 5 times.
    }
  };

  const handleDeny = (registrant) => {
    setRegistrantAccessStates((currentState) => ({
      ...currentState,
      [registrant.uid]: {
        approving: false,
        denying: true,
        remindAdminToSelectAnEvent: false
      }
    }));

    try {
      setTimeout(async () => {
        await firebase.denyRegistrantAccessToSite({
          uid: registrant.uid
        });
        resetFetchingState(registrant);
        /* Duration of Framer Motion animation */
      }, 950);
    } catch (error) {
      console.error(error);
      resetFetchingState(registrant);
    }
  };

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle="Sitewide Moderation" />
      <Wrapper>
        {!user ? (
          <LoadingSpinnerFullScreenWrapper>
            <LoadingSpinner style={{ width: '7.5rem', color: '#007FC0' }} />
          </LoadingSpinnerFullScreenWrapper>
        ) : (
          <>
            <h1>Sitewide Moderation</h1>
            {isPendingFilterSelected ? (
              pendingSiteRegistrants?.length ? (
                <>
                  <ModerationFilters
                    isPendingFilterSelected={isPendingFilterSelected}
                    setIsPendingFilterSelected={setIsPendingFilterSelected}
                  />
                  <Table>
                    <thead>
                      <tr>
                        <th rowSpan="2">Name</th>
                        <th rowSpan="2">Email</th>
                        <th rowSpan="2">Company/Institute</th>
                        <th colSpan={events.length}>Events User Will Have Access To:</th>
                        <th rowSpan="2" colSpan="2">
                          Action
                        </th>
                      </tr>
                      <tr>
                        {events.map(({ eid, name }) => (
                          <th key={eid}>{name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {pendingSiteRegistrants.map((registrant) => (
                        <tr key={registrant.uid} style={{ height: '51px' }}>
                          <td>{registrant.fullName}</td>
                          <td>{registrant.email}</td>
                          <td>{registrant.companyOrInstitutionName}</td>
                          {events.map(({ eid }) => (
                            <CheckboxWrapper
                              key={eid}
                              flash={
                                registrantAccessStates[registrant.uid]?.remindAdminToSelectAnEvent
                              }>
                              <input
                                type="checkbox"
                                onChange={handleCheckboxToggle}
                                data-eid={eid}
                                data-uid={registrant.uid}
                              />
                            </CheckboxWrapper>
                          ))}
                          <td
                            style={{
                              padding: '0.5rem'
                            }}>
                            <ActionButton
                              onClick={() => handleAccept(registrant)}
                              disabled={registrantAccessStates[registrant.uid]?.approving}
                              whileTap={{
                                scale: 0.9
                              }}
                              type="button">
                              {registrantAccessStates[registrant.uid]?.approving ? (
                                <LoadingSpinner style={{ color: '#fff', width: '1.125rem' }} />
                              ) : (
                                'Approve'
                              )}
                            </ActionButton>
                          </td>
                          <td
                            style={{
                              padding: '0.5rem'
                            }}>
                            <ActionButton
                              onClick={() => handleDeny(registrant)}
                              disabled={registrantAccessStates[registrant.uid]?.denying}
                              whileTap={{
                                scale: 0.9
                              }}
                              type="button">
                              {registrantAccessStates[registrant.uid]?.denying ? (
                                <LoadingSpinner style={{ color: '#fff', width: '1.125rem' }} />
                              ) : (
                                'Deny'
                              )}
                            </ActionButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <p>There are currently no pending site registrants</p>
              )
            ) : approvedSiteRegistrants?.length ? (
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Company/Institution Name</th>
                  </tr>
                </thead>
                <tbody>
                  {approvedSiteRegistrants.map((registrant) => (
                    <tr key={registrant.uid} style={{ height: '51px' }}>
                      <td>{registrant.fullName}</td>
                      <td>{registrant.email}</td>
                      <td>{registrant.companyOrInstitutionName}</td>
                      {/* {isPendingFilterSelected && (
                    <td
                      style={{
                        border: 'none',
                        padding: '0',
                        width: '13.25rem'
                      }}>
                      <ButtonWrapper>
                        <button
                          onClick={() => handleAccept(registrant)}
                          disabled={registrantAccessStates[registrant.uid]?.approving}
                          type="button">
                          {registrantAccessStates[registrant.uid]?.approving ? (
                            <LoadingSpinner style={{ color: '#fff', width: '1.5rem' }} />
                          ) : (
                            'Approve'
                          )}
                        </button>
                        <button onClick={() => handleDeny(registrant)} type="button">
                          Deny
                        </button>
                      </ButtonWrapper>
                    </td>
                  )} */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>There are currently no approved site registrants</p>
            )}
          </>
        )}
      </Wrapper>
    </ProtectedRoute>
  );
};

const Wrapper = styled.div`
  margin: 0 1rem;
  overflow-x: scroll;
  padding-top: 10rem;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1em;
    text-transform: capitalize;

    ${breakpoints.sm} {
      font-size: 3rem;
    }
  }

  h1,
  p {
    text-align: center;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  display: block;
  /* white-space: nowrap; */

  th,
  td {
    border: 0.063rem solid black;
    padding: 0.5rem 1.25rem;
    text-align: center;
  }
`;

const flashAnimation = keyframes`
  0% {
    background-color: white;
  }
  50% {
    background-color: rgba(255, 159, 123, 1);
  }
  100% {
    background-color: white;
  }
`;

const CheckboxWrapper = styled.td`
  background-color: white;
  ${({ flash }) =>
    flash &&
    css`
      animation: ${flashAnimation} 300ms 4;
    `}
`;

const ActionButton = styled(motion.button)`
  align-items: center;
  background-color: #0095ff;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  padding: 0.5em 1em;
  width: 5.125em;
`;

export const SiteModerationQuery = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(src/data/events)/" } }) {
      edges {
        node {
          frontmatter {
            eid
            name
          }
        }
      }
    }
  }
`;

export default SiteWideModeration;
